'use client'
import { SectionHeader } from '@/components/sections'
import UAParserContext from '@/context/ua-parser'
import { relativeGifClick } from '@/util/url'
import { GifID } from '@giphy/js-types'
import { setGADataLayer } from 'analytics'
import { useContext, useRef } from 'react'
import Grid from '../../../../components/grid/grid'
import { useGiphyFetch } from '../../../util/use-gf'
import { GifsContext } from '../gifs-context'

type Props = {
    id: string
}
const RelatedGrid = ({ id }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    let width
    let columns
    if (deviceType === 'desktop') {
        width = 776
        columns = 3
    }
    const gf = useGiphyFetch()
    const { relatedGifs: _relatedGifs } = useContext(GifsContext)
    // filter out the current id
    const relatedGifs = _relatedGifs.filter((gif) => gif.id !== id)
    // save these to report the index of the related gif clicked
    const ids = useRef<GifID[]>([])

    // Related Gif Nav is gonna fetch the first few related gifs
    return relatedGifs.length > 0 ? (
        <section>
            <SectionHeader label="Related Gifs" />
            <Grid
                initialGifs={relatedGifs}
                width={width}
                columns={columns}
                fetchGifs={async (offset: number) => {
                    const result = await gf.related(id, { offset, rating: 'pg-13' })
                    ids.current = result.data.map((gif) => gif.id)

                    return result
                }}
                onGifClick={(gif, event) => {
                    const contentType = gif.is_sticker ? 'sticker' : 'gif'
                    const data = {
                        event: 'related_content_click',
                        options: {
                            eventInfo: {
                                navElement: 'related_content',
                                navSubelement: 'related_' + contentType,
                                link: gif.url,
                            },
                            content: {
                                contentId: gif.id,
                                contentType,
                                contentIndex: ids.current.indexOf(gif.id),
                            },
                        },
                    }
                    setGADataLayer(data)
                    relativeGifClick(gif, event)
                }}
            />
        </section>
    ) : null
}
export default RelatedGrid
